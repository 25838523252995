/*@font-face {*/
/*    font-family: 'BasierCircle';*/
/*    src: url('assets/fonts/basiercircle-regular.woff2') format('woff2'),*/
/*        url('assets/fonts/basiercircle-regular.woff') format('woff'),*/
/*        url('assets/fonts/basiercircle-regular.ttf') format('truetype');*/
/*    font-weight: 400; !* Regular weight *!*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'BasierCircle';*/
/*    src: url('assets/fonts/basiercircle-medium.woff2') format('woff2'),*/
/*    url('assets/fonts/basiercircle-medium.woff') format('woff'),*/
/*    url('assets/fonts/basiercircle-medium.ttf') format('truetype');*/
/*    font-weight: 500; !* Medium weight *!*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'BasierCircle';*/
/*    src: url('assets/fonts/basiercircle-semibold.woff2') format('woff2'),*/
/*    url('assets/fonts/basiercircle-semibold.woff') format('woff'),*/
/*    url('assets/fonts/basiercircle-semibold.ttf') format('truetype');*/
/*    font-weight: 600; !* SemiBold weight *!*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'BasierCircle';*/
/*    src: url('assets/fonts/basiercircle-bold.woff2') format('woff2'),*/
/*    url('assets/fonts/basiercircle-bold.woff') format('woff'),*/
/*    url('assets/fonts/basiercircle-bold.ttf') format('truetype');*/
/*    font-weight: 700; !* Bold weight *!*/
/*    font-style: normal;*/
/*}*/

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: transparent;
}

#preload-01 {
    background: url(http://checkout.vendy.money/assets/images/failed.png) no-repeat -9999px -9999px;
}

#preload-02 {
    background: url(http://checkout.vendy.money/assets/images/spinner.svg) no-repeat -9999px -9999px;
}

#preload-03 {
    background: url(http://checkout.vendy.money/assets/images/success.png) no-repeat -9999px -9999px;
}

#preload-indicators-01 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-1.png) no-repeat -9999px -9999px;
}

#preload-indicators-02 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-2.png) no-repeat -9999px -9999px;
}

#preload-indicators-03 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-2a.png) no-repeat -9999px -9999px;
}

#preload-indicators-04 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-3.png) no-repeat -9999px -9999px;
}

#preload-indicators-05 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-3a.png) no-repeat -9999px -9999px;
}

#preload-indicators-06 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-4.png) no-repeat -9999px -9999px;
}

#preload-indicators-07 {
    background: url(http://checkout.vendy.money/assets/images/icons/indicator-4a.png) no-repeat -9999px -9999px;
}

.close-button {
    top: 20px;
    right: 20px;
    position: fixed;
    padding: 8px 10px;
    border-radius: 100px;
    background: transparent;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.close-button:hover {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 #3d3d3d;
    color: #3d3d3d;
}

.widget-container {
    max-width: 100%;
    width: 455px;
    position: relative;
    padding: 0.75rem;
    margin: auto;
}

.sub-container {
    background: #ffffff;
    border: 0.8px solid #B6DAFF;
    box-shadow: 10px 14px 16px rgba(188, 198, 209, 0.21);
    border-radius: 10px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex-grow: 1;
    overflow: hidden;
}

.widget-title img {
    width: 16px;
    vertical-align: middle;
    display: inline-block;
}

.widget-title h1,
.widget-title h2 {
    font-weight: 600;
    font-size: 14px;
    margin-left: 24px;
    color: #062558;
    display: inline-block;
}

.container-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 172%;
    text-align: center;
    color: #062558;
}

.currency-amount {
    display: inline;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 76px;
    text-align: center;
    margin-right: 0.25rem;
    color: #062558;
}

label {
    font-weight: 500;
    font-size: 14px;
    color: #062558;
}

input[type=tel],
input[type=text],
input[type=password],
input[type=number] {
    font-family: "Inter", sans-serif;
    background: #F8F8F8;
    border: 0.5px solid #E9EEF4;
    border-radius: 5px;
    height: 49px;
    width: 100%;
}

select:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=tel]:focus-visible,
input[type=text]:focus-visible,
input[type=password]:focus-visible,
input[type=number]:focus-visible {
    border: 1px solid #2F84C4 !important;
    outline: none !important;
}

.phone {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 1.2px;
    text-align: center;
    color: #062558;
}

.form-input {
    background: #FFFFFF;
    border: 0.5px solid #E9EEF4;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 1.2px;
    text-align: center;
    color: #062558;
}

.form-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 149%;
    color: rgba(6, 37, 88, 0.24);
}

.input-currency {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 149%;
    text-align: right;
    color: #062558;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
    border: 0.5px solid #E9EEF4 !important;
    border-radius: 5px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 106.05%;
    color: #062558;
    height: 54px;
    box-shadow: none !important;
}

.css-1s2u09g-control:hover {
    border: 0.5px solid #E9EEF4 !important;
    border-radius: 5px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 106.05%;
    color: #062558;
    height: 54px;
}

.css-tlfecz-indicatorContainer {
    color: #062558 !important;
}

.css-1hb7zxy-IndicatorsContainer,
.css-1okebmr-indicatorSeparator {
    display: none;
}

.currency-field {
    background: #FBFBFB;
    border: 1px solid #E9EEF4;
    border-radius: 4px;
    margin-top: 8px;
}

.currency-flag {
    border-right: 1px solid #E9EEF4;
    background: #FBFBFB;
    padding: 10px 20px;
    float: left;
}

.currency-flag img {
    height: 14px;
}

.muted {
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 77px;
    text-align: center;
    color: rgba(6, 37, 88, 0.4);
}

button[type=submit],
button[type=reset] {
    width: 100%;
    border-radius: 5px;
    border: none;
    margin: 40px 0;
    padding: 16px;
    text-align: center;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
}

.btn-submit {
    width: 100%;
    border-radius: 5px;
    border: none;
    margin: 40px 0;
    padding: 16px;
    text-align: center;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
}

.btn-default {
    background: linear-gradient(75.08deg, #2269B3 16.08%, #3FA6D9 105.3%);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
}

.btn-default-alt {
    background: #FFFFFF;
    border: 1px solid #E9EEF4 !important;
    color: #062558;
}

.btn-disabled {
    background: #DEDEDE;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 105.5%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #F3F3F3;
}

.text-warning {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #F43C3C;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.spinner {
    animation: rotation 1s infinite linear;
    max-width: 90px;
}

.grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.success-status {
    max-width: 224px;
    width: 100%;
    margin-right: 24px;
}

.failed-status {
    max-width: 96px;
    width: 100%;
    margin: 16px;
}

.progress-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.progress-bar {
    top: 18px;
    width: 98%;
    margin-left: 1%;
    position: absolute;
    border: 1px solid #E9EEF4;
    background: #F8F8F8;
    border-radius: 5px;
    height: 6px;
}

.active-progress {
    background: linear-gradient(75.08deg, #2269B3 16.08%, #3FA6D9 105.3%);
    border-radius: 5px;
    height: 6px;
}

.progress-indicator {
    text-align: center;
    z-index: 1;
}

.indicator-icon {
    background: #F8F8F8;
    border: 1px solid #E9EEF4;
    border-radius: 50%;
    padding: 10px;
    margin: auto;
    width: 22px;
}

.indicator-icon img {
    max-width: 18px;
}

.indicator-icon.active {
    background: linear-gradient(75.08deg, #2269B3 16.08%, #3FA6D9 105.3%);
    border: none;
}

.indicator-label {
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #96A3B9;
}

.indicator-label.active {
    color: #062558;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.table-div {
    background: #FBFDFF;
    border: 1px solid #FAFCFF;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #062558;
    overflow-x: auto;
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.custom-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-toggle .line {
    display: block;
    background-color: rgba(236, 25, 28, 1);
    width: 3.5rem;
    height: 2rem;
    border-radius: 9999px;
}

.custom-toggle .dot {
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    background-color: #FFFFFF;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    transition-property: background-color, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.custom-toggle input:checked~.dot {
    transform: translateX(100%);
}

.custom-toggle input:checked~.line {
    background: #61B467;
}

.custom-toggle input:disabled~.line {
    opacity: 0.3;
}

.fees-div {
    display: flex;
    margin-top: 1rem;
    background: #FFFFFF;
    border: 0.534825px solid #F4F6FD;
    border-radius: 4px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

/* ================================================================================================================== */

.payment-widget {
    padding: 20px;
    text-align: center;
}

.payment-widget .payment-widget-warning {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.36);
    border-radius: 4px;
    text-align: left;
    font-size: 12px;
    padding: 10px;
}

.payment-widget .notification-banner-warning {
    background: rgba(198, 167, 0, 0.10);
    position: relative;
    border-radius: 4px;
    margin-top: 32px;
    padding: 10px;
    font-size: 12px;
    text-align: left;
    color: rgba(0, 0, 0, 0.70);
    text-decoration: none;
}

.payment-widget .notification-banner-warning img {
    position: absolute;
    width: 14px;
    left: 12px;
    top: 14px;
}

.payment-widget .notification-banner-warning div {
    margin-left: 28px;
}

.payment-widget .payment-widget-image {
    margin-top: 72px;
}

.payment-widget img {
    max-width: 230px;
}

.payment-widget .payment-widget-content {
    margin-top: 48px;
    box-shadow: 0 -108px 64px #FFFFFF;
    background: #FFFFFF;
}

.payment-widget h1 {
    font-family: 'Rowdies', 'Inter', sans-serif;
    font-weight: 400;
    font-size: 32px;
    margin: 0;
}

.payment-widget h2 {
    color: rgba(0, 0, 0, 0.50);
    font-weight: 400;
    font-size: 16px;
    margin: 10px auto;
    text-align: center;
}

.payment-widget .pay-button {
    border-radius: 50px;
    background: #1E7CDA;
    margin: 24px 0;
    padding: 10px;
}

.payment-widget hr {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin: 24px 0;
}

.payment-widget .pay-button a {
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    padding: 10px;
}

.payment-widget .terms-disclosure {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-size: 12px;
}

.payment-widget .terms-disclosure a {
    font-weight: 500;
    color: #000000;
}

.payment-widget .footer-logo {
    margin-top: 54px;
}

.payment-widget .footer-logo img {
    max-width: 80px;
}

.chat-bubble {
    color: #FFFFFF;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    max-width: 188px;
    position: fixed;
    right: 32px;
    top: 0;
}

.chat-bubble img {
    float: right;
    width: 20px;
    margin: 0;
    right: 0;
}

.chat-bubble div {
    background: #1E7CDA;
    border-radius: 4px 0 4px 4px;
    padding: 12px;
    clear: both;
}

.chat-bubble h4 {
    font-weight: 700;
    margin: 0;
}

.chat-bubble p {
    margin: 8px 0 8px;
}

.eula-intro.container {
    /*!*font-family: BasierCircle, sans-serif;*! TODO*/
    font-family: Inter, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 16px 160px;
    background-color: #FFFFFF;
    font-size: 13px;
}

.eula-intro .heading {
    max-width: 256px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #000;
}

.eula-intro .subheading {
    max-width: 256px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #000;
}

.eula-intro .summary .key-points, .full-terms-card .links {
    list-style-type: none;
    padding-left: 0;
}

.full-terms-card .links li a {
    font-weight: 500;
    color: #008CFF;
}

.eula-intro .summary .key-points li {
    color: #555555;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 40px;
    max-width: 340px;
}

.eula-intro .summary .key-points li::before {
    content: "";
    width: 20px;
    height: 40px;
    display: inline-block;
    background-image: url('http://checkout.vendy.money/assets/images/icons/terms-key-point.png'); /* Path to your custom icon */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    vertical-align: middle;
    margin-right: 12px;
    float: left;
}

.eula-intro .full-terms-card {
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    background: #F5F5F5;
    position: absolute;
    padding: 12px;
    bottom: 180px;
    right: 16px;
    left: 16px;
}

/*.eula-intro .summary .key-points li::before {*/
/*    content: url('http://checkout.vendy.money/assets/images/icons/terms-key-point.png');*/
/*    width: 21px; height: 21px;*/
/*    padding-right: 12px; !* Add space between the icon and text *!*/
/*    !* Adjust vertical alignment as needed *!*/
/*    vertical-align: middle;*/
/*}*/

.eula.container {
    font-family: inter, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 16px 160px;
    background-color: #FFFFFF;
    font-size: 13px;
}

.eula .heading {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 12px;
    color: #000;
}

.eula .subheading {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 12px;
    color: #000;
}

.eula .accordion-title {
    border: none;
    cursor: pointer;
    margin-top: 24px;
    background-color: transparent;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 0;
    color: #000;
}

.eula .accordion-content {
    display: none;
    padding: 12px 0 0;
    font-weight: 400;
    font-size: 13px;
    color: #555;
}

.eula .arrow {
    float: right;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.eula-intro .footer-container, .eula .footer-container {
    position: fixed;
    background: #FFFFFF;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    box-shadow: 0 1px 12px 8px rgba(0, 0, 0, 0.05);
    padding: 8px 16px 28px;
    bottom: 0;
    right: 0;
    left: 0;
}

.eula-intro .checkbox-container, .eula .checkbox-container {
    padding: 18px 8px;
}

.eula-intro .checkbox-container input, .eula .checkbox-container input {
    display: none;
}

.eula-intro .checkbox-container label, .eula .checkbox-container label {
    font-weight: 400;
    font-size: 14px;
    color: #000;
}

.eula-intro .checkbox-container label {
    font-size: 13px;
    color: #555555;
}

.eula-intro .chakra-checkbox, .eula .chakra-checkbox {
    margin-right: 10px;
    float: left;
}

.eula-intro .chakra-button, .eula .chakra-button {
    width: 100%;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 50px;
    font-family: "Inter", sans-serif;
    background-color: #008CFF;
    padding: 12px 10px;
    font-size: 14px;
    cursor: pointer;
    border: none;
}

.eula-intro .chakra-button:hover, .eula .chakra-button:hover {
    background-color: #0056b3;
}

.eula-intro .chakra-button:disabled, .eula .chakra-button:disabled {
    background-color: #D9D9D9;
}
